<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>
        {{collectionsConfig.getCollectionLabel(collectionId)}} <small> - {{collectionId}}</small>
      </v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-toolbar-items>
        <v-btn
          text
          @click="$refs.schemaEditor.addNewAttribute()">
          <v-icon>add</v-icon>
          {{translate('Add attribute', 'add-attribute')}}
        </v-btn>
        <v-btn
          text
          :disabled="!canSave"
          @click="saveSchema(schemaUpdate)">
          <v-icon>save</v-icon> {{translate('Save', 'save')}}
        </v-btn>
      </v-toolbar-items>
    </template>

    <v-form ref="form" v-model="validForm">
      <v-card>
        <v-card-title>
          {{collectionsConfig.getCollectionLabel(collectionId)}} <small> - {{collectionId}}</small>
        </v-card-title>
        <v-card-text>
          <schema-editor
            ref="schemaEditor"
            :loading="details.loading"
            :schema="details.schema"
            :disable-labels="true"
            :enable-keys="true"
            @update-schema="schemaUpdate = $event"
          />
        </v-card-text>
      </v-card>
    </v-form>
  </layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'
import SchemaEditor from '@/components/schemas/schema-editor.vue'

export default {
  props: ['collectionId'],
  data: () => ({
    schemaUpdate: null,
    validForm: false,
    mutations: 1
  }),
  components: {
    LayoutPage,
    SchemaEditor
  },
  computed: {
    ...mapGetters(['api', 'collectionsConfig', 'translate']),
    canSave () {
      return (this.schemaUpdate !== null) && this.validForm
    }
  },
  asyncComputed: {
    details: {
      async get () {
        let schema = this.mutations && await this.api.collections.getCollectionSchema({
          collectionId: this.collectionId,
          cached: false
        })
        return {
          loading: false,
          schema
        }
      },
      default: {
        loading: true,
        schema: {}
      }
    }
  },
  methods: {
    async saveSchema(schemaUpdate) {
      await this.api.collections.updateCollectionSchema({update: schemaUpdate})
      this.mutations = this.mutations + 1
    }
  }
}
</script>
